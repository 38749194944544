// 常量路由

import operationRouter from '@/router/modules/operation'
import systemManageRouter from '@/router/modules/systemManage'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '控制台'
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  // 人民调解
  {
    path: '/mediate/list',
    name: 'MediateList',
    component: () => import('@/views/mediate/list.vue'),
    meta: { name: '人民调解', icon: 'el-icon-wallet' }
  },
  {
    path: '/mediate/add',
    name: 'MediateCaseAdd',
    component: () => import('@/views/mediate/addOrEdit.vue'),
    meta: { name: '新增人民调解', hidden: true, activeMenu: '/mediate/list' }
  },
  {
    path: '/mediate/edit/:id?',
    name: 'MediateCaseEdit',
    component: () => import('@/views/mediate/addOrEdit.vue'),
    meta: { name: '编辑人民调解', hidden: true, activeMenu: '/mediate/list' }
  },
  {
    path: '/mediate/detail/:id?',
    name: 'MediateCaseDetail',
    component: () => import('@/views/mediate/detail.vue'),
    meta: { name: '人民调解详情', hidden: true, activeMenu: '/mediate/list' }
  },
  // 纠纷排查

  {
    path: '/dispute/check/list',
    name: 'DisputeCheckList',
    component: () => import('@/views/dispute-check/list.vue'),
    meta: { name: '纠纷排查', icon: 'el-icon-bank-card' }
  },
  {
    path: '/dispute/check/detail/:id?',
    name: 'DisputeCheckDetail',
    component: () => import('@/views/dispute-check/detail.vue'),
    meta: { name: '纠纷排查详情', hidden: true, activeMenu: '/dispute/check/list' }
  },
  // 群众咨询
  {
    path: '/user-consult',
    name: 'UserConsult',
    meta: {
      icon: 'el-icon-mobile',
      name: '群众咨询'
    },
    component: () => import('../views/user-consult/list.vue')
  },
  {
    path: '/user-consult/detail/:id?',
    name: 'UserConsultDetail',
    component: () => import('@/views/user-consult/detail.vue'),
    meta: { name: '群众咨询详情', hidden: true, activeMenu: '/user-consult' }
  },
  // 通讯录
  {
    path: '/address/list',
    name: 'AddressList',
    component: () => import('@/views/address/list.vue'),
    meta: { name: '通讯录', icon: 'el-icon-notebook-2' }
  },
  {
    path: '/address/add',
    name: 'AddressAdd',
    component: () => import('@/views/address/addOrEdit.vue'),
    meta: { name: '新增通讯录', hidden: true, activeMenu: '/address/list' }
  },
  {
    path: '/address/edit/:id?',
    name: 'AddressEdit',
    component: () => import('@/views/address/addOrEdit.vue'),
    meta: { name: '编辑通讯录', hidden: true, activeMenu: '/address/list' }
  },

  // 组织架构
  {
    path: '/org/list',
    name: 'OrgList',
    component: () => import('@/views/org/index.vue'),
    meta: { name: '组织架构', icon: 'el-icon-s-grid' }
  },
  // 群众端用户管理

  {
    path: '/customers/list',
    name: 'CustomerList',
    component: () => import('@/views/customers/customer-list/list.vue'),
    meta: { name: '群众端用户列表', icon: 'el-icon-user-solid' }
  },
  {
    path: '/customers/detail/:id?',
    name: 'CustomerDetail',
    component: () => import('@/views/customers/customer-list/detail.vue'),
    meta: { name: '群众端用户详情', hidden: true, activeMenu: '/customers/list' }
  },
  // 司法端用户管理

  {
    path: '/law_people/people/list',
    name: 'LawPeopleList',
    component: () => import('@/views/law-people/law-people/list.vue'),
    meta: { name: '司法端用户列表', icon: 'el-icon-s-custom' }
  },
  {
    path: '/law_people/people/add',
    name: 'LawPeopleAdd',
    component: () => import('@/views/law-people/law-people/addOrEdit.vue'),
    meta: { name: '司法端身份新增', hidden: true, activeMenu: '/law_people/people/list' }
  },
  {
    path: '/law_people/people/edit/:id?',
    name: 'LawPeopleEdit',
    component: () => import('@/views/law-people/law-people/addOrEdit.vue'),
    meta: { name: '司法端身份编辑', hidden: true, activeMenu: '/law_people/people/list' }
  },
  {
    path: '/law_people/user/add',
    name: 'LawPeopleUserAdd',
    component: () => import('@/views/law-people/user/addOrEdit.vue'),
    meta: { name: '司法端用户新增', hidden: true, activeMenu: '/law_people/user/list' }
  },
  {
    path: '/law_people/user/edit/:id?',
    name: 'LawPeopleUserEdit',
    component: () => import('@/views/law-people/user/addOrEdit.vue'),
    meta: { name: '司法端用户编辑', hidden: true, activeMenu: '/law_people/user/list' }
  },
  // 法律服务
  {
    path: '/attorney',
    name: 'Attorney',
    meta: {
      icon: 'el-icon-c-scale-to-original',
      name: '法律服务'
    },
    component: () => import('@/views/attorney/index.vue'),
    children: [
      {
        path: '/attorney/advice/list',
        name: 'AttorneyAdviceList',
        component: () => import('@/views/attorney/advice/list.vue'),
        meta: { name: '法律咨询' }
      },
      {
        path: '/attorney/advice/add',
        name: 'AttorneyAdviceAdd',
        component: () => import('@/views/attorney/advice/addOrEdit.vue'),
        meta: { name: '新增法律咨询', hidden: true, activeMenu: '/attorney/advice/list' }
      },
      {
        path: '/attorney/advice/edit/:id?',
        name: 'AttorneyAdviceEdit',
        component: () => import('@/views/attorney/advice/addOrEdit.vue'),
        meta: { name: '编辑法律咨询', hidden: true, activeMenu: '/attorney/advice/list' }
      },
      {
        path: '/attorney/public/list',
        name: 'AttorneyPublicList',
        component: () => import('@/views/attorney/public/list.vue'),
        meta: { name: '法律宣传' }
      },
      {
        path: '/attorney/public/add',
        name: 'AttorneyPublicAdd',
        component: () => import('@/views/attorney/public/addOrEdit.vue'),
        meta: { name: '新增法律宣传', hidden: true, activeMenu: '/attorney/public/list' }
      },
      {
        path: '/attorney/public/edit/:id?',
        name: 'AttorneyPublicEdit',
        component: () => import('@/views/attorney/public/addOrEdit.vue'),
        meta: { name: '编辑法律宣传', hidden: true, activeMenu: '/attorney/public/list' }
      },
      // {
      //   path: '/attorney/area/list',
      //   name: 'AttorneyAreaList',
      //   component: () => import('@/views/attorney/area/list.vue'),
      //   meta: { name: '区域管理' }
      // },
      // {
      //   path: '/attorney/area/addEdit/:parent_id?',
      //   name: 'AttorneyAreaAdd',
      //   component: () => import('@/views/attorney/area/addEdit.vue'),
      //   meta: { name: '新增', hidden: true, activeMenu: '/attorney/area/list' }
      // },
      // {
      //   path: '/attorney/area/addEdit/:menuId?',
      //   name: 'AttorneyAreaEdit',
      //   component: () => import('@/views/attorney/area/addEdit.vue'),
      //   meta: { name: '编辑', hidden: true, activeMenu: '/attorney/area/list' }
      // },
      {
        path: '/attorney/interpretation-case/list',
        name: 'AttorneyInterpretationCaseList',
        component: () => import('@/views/attorney/interpretation-case/list.vue'),
        meta: { name: '以案释法' }
      },
      {
        path: '/attorney/interpretation-case/add',
        name: 'AttorneyInterpretationCaseAdd',
        component: () => import('@/views/attorney/interpretation-case/addOrEdit.vue'),
        meta: { name: '新增以案释法', hidden: true, activeMenu: '/attorney/interpretation-case/list' }
      },
      {
        path: '/attorney/interpretation-case/edit/:id?',
        name: 'AttorneyInterpretationCaseEdit',
        component: () => import('@/views/attorney/interpretation-case/addOrEdit.vue'),
        meta: { name: '编辑以案释法', hidden: true, activeMenu: '/attorney/interpretation-case/list' }
      }
    ]
  },

  // 权限管理
  {
    path: '/powerManage',
    name: 'PowerManage',
    redirect: '/powerManage/user/list',
    component: () => import('@/views/powerManage/index.vue'),
    meta: { name: '权限管理', icon: 'el-icon-menu' },
    children: [
      {
        path: '/powerManage/user/list',
        name: 'PowerManageUserList',
        component: () => import('@/views/powerManage/user/list.vue'),
        meta: { name: '管理员' }
      },
      {
        path: '/powerManage/user/add',
        name: 'PowerManageUserAdd',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '新增管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/user/edit/:id?',
        name: 'PowerManageUserEdit',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '编辑管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/role/list',
        name: 'RoleList',
        component: () => import('@/views/powerManage/role/list.vue'),
        meta: { name: '角色管理' }
      },
      {
        path: '/powerManage/role/addEdit',
        name: 'RoleAdd',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '新增角色', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/role/addEdit/:id?',
        name: 'RoleEdit',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '编辑角色', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/menu/list',
        name: 'MenuList',
        component: () => import('@/views/powerManage/menu/list.vue'),
        meta: { name: '菜单管理', keepAlive: true }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuAdd',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '新增菜单', hidden: true, activeMenu: '/powerManage/menu/list' }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuEdit',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '编辑菜单', hidden: true, activeMenu: '/powerManage/menu/list' }
      }
      // {
      //   path: '/powerManage/dept/list',
      //   name: 'Dept',
      //   component: () => import('@/views/powerManage/dept/list.vue'),
      //   meta: { name: '部门管理' }
      // }
    ]
  },

  systemManageRouter
]
