import Vue from 'vue'
import AdminTitle from '../components/admin-title/admin-title.vue'
import uploader from '@/components/uploader/index.vue'
import dictSelect from '@/components/dict-select/index.vue'
import Chat from '@/components/chat/chat.vue'

import TpTable from '@/components/tp-table/index.vue'
const components = [AdminTitle, TpTable, uploader, dictSelect, Chat]

export default function install() {
  components.forEach((item) => Vue.component(item.name, item))
}
