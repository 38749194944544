import { http } from '@/http/axios.js'
// 列表
export function getWWWInfoAPI() {
    return http({
        url: `/company/basic/product/getWwwInfo`,
        method: 'get'
    })
}


export function updatePasswordAPI(data) {
    return http({
        url: `/company/basic/passport/resetPassword`,
        method: 'POST',
        data
    })
}


