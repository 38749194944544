const systemManageRouter = {
  path: '/systemManage',
  name: 'SystemManage',
  redirect: '/systemManage/dictManage/list',
  component: () => import('@/views/systemManage/index.vue'),
  meta: { name: '系统设置', icon: 'el-icon-setting' },
  children: [
    // {
    //     path: '/systemManage/dictManage/list',
    //     name: 'DictManageList',
    //     component: () => import('@/views/systemManage/dictManage/index.vue'),
    //     meta: {name: '字典管理'}
    // },
    // {
    //     path: '/systemManage/dictManage/addOrEdit',
    //     name: 'dictManageAddOrEdit',
    //     component: () => import('@/views/systemManage/dictManage/detail-dict.vue'),
    //     meta: {hidden: true, name: '字典管理'}
    // },
    // {
    //     path: '/systemManage/dictValueManage',
    //     name: 'DictValueManage',
    //     component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
    //     meta: {hidden: true, name: '字典值管理'}
    // },
    // {
    //     path: '/systemManage/dictValueManage/addOrEdit/:id?',
    //     name: 'DictValueAddOrEdit',
    //     component: () => import('@/views/systemManage/dictManage/detail-dict-value.vue'),
    //     meta: {hidden: true, name: '字典值管理'}
    // },
    {
      path: '/systemManage/config/list',
      name: 'ConfigList',
      component: () => import('@/views/systemManage/config/list.vue'),
      meta: { name: '系统设置' }
    },
    {
      path: '/systemManage/announcement/list',
      name: 'AnnouncementList',
      meta: {
        // icon: 'el-icon-tickets',
        name: '通知公告'
      },
      component: () => import('@/views/systemManage/announcement/list.vue')
    },
    {
      path: '/systemManage/announcement/edit/:id?',
      name: 'AnnouncementEdit',
      component: () => import('@/views/systemManage/announcement/addOrEdit.vue'),
      meta: { name: '编辑', hidden: true, activeMenu: '/systemManage/announcement/list' }
    },
    {
      path: '/systemManage/announcement/add',
      name: 'AnnouncementAdd',
      component: () => import('@/views/systemManage/announcement/addOrEdit.vue'),
      meta: { name: '新增', hidden: true, activeMenu: '/systemManage/announcement/list' }
    },
    {
      path: '/systemManage/setting_banner',
      name: 'SettingBanner',
      component: () => import('@/views/systemManage/banner/list.vue'),
      meta: { name: 'banner设置' }
    },
    {
      path: '/systemManage/agreement/list',
      name: 'AgreementList',
      component: () => import('@/views/systemManage/agreement/list.vue'),
      meta: { name: '协议设置' }
    },

    {
      path: '/systemManage/dispute/category/list',
      name: 'DisputeCategoryList',
      component: () => import('@/views/systemManage/dispute-category/list.vue'),
      meta: { name: '纠纷类型' }
    },
    {
      path: '/systemManage/dispute/category/add',
      name: 'DisputeCategoryAdd',
      component: () => import('@/views/systemManage/dispute-category/addOrEdit.vue'),
      meta: { name: '新增纠纷类型', hidden: true, activeMenu: '/systemManage/dispute/category/list' }
    },
    {
      path: '/systemManage/dispute/category/edit/:id?',
      name: 'DisputeCategoryEdit',
      component: () => import('@/views/systemManage/dispute-category/addOrEdit.vue'),
      meta: { name: '编辑纠纷类型', hidden: true, activeMenu: '/systemManage/dispute/category/list' }
    },
    // 区域管理
    {
      path: '/systemManage/area/list',
      name: 'AreaList',
      component: () => import('@/views/systemManage/area/list.vue'),
      meta: { name: '区域管理', keepAlive: true }
    },
    {
      path: '/systemManage/area/addEdit/:parent_id?',
      name: 'AreaAdd',
      component: () => import('@/views/systemManage/area/addEdit.vue'),
      meta: { name: '新增区域', hidden: true, activeMenu: '/systemManage/area/list' }
    },
    {
      path: '/systemManage/area/addEdit/:menuId?',
      name: 'AreaEdit',
      component: () => import('@/views/systemManage/area/addEdit.vue'),
      meta: { name: '编辑区域', hidden: true, activeMenu: '/systemManage/area/list' }
    },
    // 片区管理
    {
      path: '/systemManage/district/list',
      name: 'DistrictList',
      component: () => import('@/views/systemManage/district/list.vue'),
      meta: { name: '片区管理' }
    },
    {
      path: '/systemManage/district/add',
      name: 'DistrictAdd',
      component: () => import('@/views/systemManage/district/addOrEdit.vue'),
      meta: { name: '新增片区', hidden: true, activeMenu: '/systemManage/district/list' }
    },
    {
      path: '/systemManage/district/edit',
      name: 'DistrictEdit',
      component: () => import('@/views/systemManage/district/addOrEdit.vue'),
      meta: { name: '编辑片区', hidden: true, activeMenu: '/systemManage/district/list' }
    },
    {
      path: '/systemManage/org/list',
      name: 'OrgList',
      component: () => import('@/views/systemManage/org/list.vue'),
      meta: { name: '组织管理' }
    },
    {
      path: '/systemManage/org/add',
      name: 'OrgAdd',
      component: () => import('@/views/systemManage/org/addOrEdit.vue'),
      meta: { name: '新增组织', hidden: true, activeMenu: '/systemManage/org/list' }
    },
    {
      path: '/systemManage/org/edit/:id?',
      name: 'OrgEdit',
      component: () => import('@/views/systemManage/org/addOrEdit.vue'),
      meta: { name: '编辑组织', hidden: true, activeMenu: '/systemManage/org/list' }
    }
  ]
}

export default systemManageRouter
