var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(!_vm.isLoginPage)?_c('a-layout',[_c('a-layout-sider',{attrs:{"trigger":null,"collapsible":""},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('left-menu')],1),_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0"}},[_c('top-menu',{model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}})],1),_c('div',[_c('TagsView')],1),_c('a-layout',[_c('a-layout-sider',{style:({
            marginBottom: '24px',
            background: '#fff',
            paddingTop: '24px',
            maxWidth: '140px',
            minWidth: '0',
            flex: 'unset',
            width: 'auto'
          })}),_c('a-layout-content',{style:({ margin: '24px 16px', padding: '24px', background: '#fff', display: 'flex', width: '800px' })},[(_vm.isRouterAlive)?[(_vm.$route.meta.keepAlive)?_c('keep-alive',[_c('router-view')],1):_vm._e(),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()]:_vm._e()],2)],1)],1)],1):[(_vm.$route.meta.keepAlive)?_c('keep-alive',[_c('router-view')],1):_vm._e(),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],_c('Chat',{ref:"chat",attrs:{"isLoginPage":_vm.isLoginPage}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }