<template>
  <div class="wrapper">
    <el-dialog title="" :close-on-click-modal="false" :visible.sync="show" width="700px" :show-close="false">
      <div class="chat-box">
        <TUICallKit :videoResolution="VideoResolution.RESOLUTION_1080P" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TUICallKit, TUICallKitServer, TUICallType, VideoDisplayMode, VideoResolution } from '@tencentcloud/call-uikit-vue2'
import * as GenerateTestUserSig from '@/debug/GenerateTestUserSig-es.js'
import TIM from '@tencentcloud/chat'
import { TUICallEvent } from 'tuicall-engine-webrtc'
import { mapState } from 'vuex'
export default {
  name: 'Chat',
  props: {
    isLoginPage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TUICallKit
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.user
    })
  },

  data() {
    return {
      VideoDisplayMode,
      VideoResolution,
      show: false,
      // SDKAppID、userSig 的获取参考下面步骤

      // SDKAppID: 1600040534, // Replace with your SDKAppID
      // SecretKey: '8de41b9deaef4be18edb7dc608adad516e059eb33297066bf0a901244cc38c89', // Replace with your SecretKey
      userIDList: ''
    }
  },

  mounted() {
    this.$bus.$on('loginchat', () => {
      this.init()
    })

    setTimeout(async () => {
      if (!this.isLoginPage && this.$route.path != '/home') {
        // await TUICallKitServer.destroyed()
        // this.init()
      } else {
        // await TUICallKitServer.destroyed()
        // console.log('销毁')
      }
    }, 500)
  },

  methods: {
    async init() {
      // const { userSig } = GenerateTestUserSig.genTestUserSig({
      //   userID: this.userInfo.tim_userid,
      //   SDKAppID: Number(this.userInfo.tim_appid),
      //   SecretKey: this.userInfo.tim_token
      // })
      // 初始化
      await TUICallKitServer.init({
        SDKAppID: Number(this.userInfo.tim_appid),
        userID: this.userInfo.tim_userid,
        userSig: this.userInfo.tim_token
        // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
      })

      // TUICallKitServer.enableFloatWindow(true) //悬浮框
      // // 接收来电
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.ON_CALL_RECEIVED, this.handleUserReceived)
      // // 取消
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.ON_CALL_CANCELED, this.handleUserCancel)
      // // 拒绝
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.REJECT, this.handleUserCancel)
      // // 结束事件
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.CALLING_END, this.handleUserCancel)
      // TUICallKitServer.setSelfInfo({ nickName: this.userInfo.username })
      // try {
      //   await TUICallKitServer.setSelfInfo({ nickName: this.userInfo.username })
      //   console.log('昵称设置成功：', this.userInfo.username)
      // } catch (error) {
      //   console.log(`[TUICallKit] Failed to call the setSelfInfo API. Reason: ${error}`)
      //   this.$alert(`昵称设置错误`, '提示', {
      //     confirmButtonText: '确定',
      //     type: 'warning',
      //     callback: (action) => {
      //       this.show = false
      //     }
      //   })
      // }
      console.log('登录成功', '我：' + this.userInfo.tim_userid)
    },
    async init_call() {
      // 初始化
      await TUICallKitServer.init({
        SDKAppID: Number(this.userInfo.tim_appid),
        userID: this.userInfo.tim_userid,
        userSig: this.userInfo.tim_token
        // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
      })

      // TUICallKitServer.enableFloatWindow(true) //悬浮框
      // // 接收来电
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.ON_CALL_RECEIVED, this.handleUserReceived)
      // // 取消
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.ON_CALL_CANCELED, this.handleUserCancel)
      // // 拒绝
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.REJECT, this.handleUserCancel)
      // // 结束事件
      TUICallKitServer.getTUICallEngineInstance().on(TUICallEvent.CALLING_END, this.handleUserCancel)
      this.call()
    },
    handleUserReceived() {
      console.log('来电')
      this.show = true
    },
    async call() {
      try {
        // 1v1 video call
        await TUICallKitServer.call({ userID: this.userIDList, type: TUICallType.VIDEO_CALL })
        console.log('对方id:' + this.userIDList)
      } catch (error) {
        console.log('聊天报错：', `[TUICallKit] Call failed. Reason: ${error}`)
        // alert(`[TUICallKit] Call failed. Reason: ${error}`)
        this.$alert(`对方账户未登录`, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
            this.show = false
          }
        })
      }
    },
    async createGroupID() {
      const tim = TIM.create({ SDKAppID: this.userInfo.tim_appid })
      const memberList = this.userIDList.map(userId => ({ userID: userId }))
      const res = await tim.createGroup({
        type: TIM.TYPES.GRP_PUBLIC, // Must be a public group
        name: 'WebSDK',
        memberList: memberList
      })
      this.groupID = res.data.group.groupID
      console.log('群组id：', res.data.group.groupID)
      // 发起通话
      try {
        const params = {
          userIDList: this.userIDList,
          groupID: res.data.group.groupID,
          type: TUICallType.VIDEO_CALL
        }
        console.log(params)
        await TUICallKitServer.groupCall(params)
      } catch (error) {
        alert(`[TUICallKit] groupCall failed. Reason:${error}`)
      }
    },

    async handleUserCancel() {
      console.log('结束通话')
      this.show = false
      await TUICallKitServer.destroyed()
      this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-box {
  width: 100%;
  height: 50vh;
  // border: 1px solid salmon;
}

.wrapper /deep/ {
  .el-dialog {
    background: transparent;
    box-shadow: none;
  }
}
</style>
