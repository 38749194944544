<template>
    <el-select size="small" v-model="dictValue" placeholder="" @change="updateDictValue">
        <el-option v-for="item in dictList" :key="item.value" :label="item.name"
                   :value="item.value"></el-option>
    </el-select>
</template>

<script>

import {dictEnumsApi} from "@/api/dict";

export default {
    name: "dictSelect",

    data() {
        return {
            dictList: []
        }
    },

    props: {
        // 传入字典的常量值
        dictKey: {
            type: [String],
            default: ''
        },
        dictValue: {
            type: [Number, String, Array],
            default: 0
        }
    },
    watch: {
        // inputValue: {
        //     immediate: true,
        //     deep: true,
        //     handler (val) {
        //         this.dictValue = val.toString()
        //     }
        // }
    },
    mounted() {
        dictEnumsApi(this.dictKey).then(res => {
            this.dictList = res
        })
    },

    methods: {
        updateDictValue() {
            this.$emit('updateDictValue', this.dictValue)
        }
    }

}
</script>

<style scoped>

</style>
