import { http } from '@/plugins/axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { router } from '@/router'
import { loginApi, logoutApi } from '@/api/manage'
import { setCookie } from '@/utils/support'
import { asyncRoutes, constantRoutes, anyRoutes } from '@/router/routes'

const computeAsyncRoutes = (oriAsyncRoutes, authMenus = []) => {
  return oriAsyncRoutes.filter((item) => {
    if (authMenus.indexOf(item.name) != -1) {
      if (item.children && item.children.length > 0) {
        item.children = computeAsyncRoutes(item.children, authMenus)
      }
      return true
    }
  })
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    user: {},
    isAdmin: 0,
    authMenus: [],
    // authBtns: [],
    resultAsyncRoutes: [], // 对比后的最终路由
    resultAllRoutes: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AUTH_MENU: (state, authMenu) => {
      state.authMenus = authMenu
      state.authBtns = authMenu
    },
    SET_IS_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_INFO: (state, userInfo) => {
      state.user = userInfo
    },
    SET_RESULT_ASYNC_ROUTES: (state, asyncRoutes) => {
      state.resultAsyncRoutes = asyncRoutes
      let routes = constantRoutes.concat(asyncRoutes, anyRoutes)
      routes.forEach((item) => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })

      router.addRoutes(routes)
    }
  },

  actions: {
    // 登录
    Login({ commit, dispatch }, userInfo) {
      // const account = userInfo.account.trim()
      return new Promise((resolve, reject) => {
        // let loginData = {
        //   account,
        //   password: userInfo.password
        // }

        loginApi(userInfo)
          .then((res) => {
            setToken(res.authorization)
            commit('SET_USER_INFO', { ...res.login_info, tim_appid: res.tim_appid, tim_token: res.tim_token })
            commit('SET_IS_ADMIN', res?.login_info?.is_super || 0)
            commit('SET_AUTH_MENU', res?.auth_perms || [])
            resolve()
          })
          .catch((error) => {
            console.log('error-----', error)
            reject(error)
          })
      })
    },

    // 退出
    Logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        logoutApi()
          .then(() => {
            dispatch('tagsView/delAllViews')
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  },
  getters: {
    getUserInfo: (state) => state.user,

    authBtns: (state) => state.authBtns,

    isAdmin: (state) => state.user.is_super,

    getResultRoutes: (state) => {
      const calAsyncRoutes = state.user.is_super ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), state.authMenus)

      let res = constantRoutes.concat(calAsyncRoutes, anyRoutes)

      res.forEach((item) => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })

      router.addRoutes(res)
      return res
    }
  }
}

export default user
