<template>
  <div class="top-menu">
    <div class="left">
      <a-icon class="icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="changeCollapsed" />
      <a-icon class="icon" type="reload" @click="refresh" />
      <!-- <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for=" item in bread" :key="item.path" :to="{ path:item.path }">{{item.name}}</el-breadcrumb-item>

            </el-breadcrumb> -->
    </div>

    <div class="right">
      <el-dropdown trigger="hover" @command="handleCommand">
        <span style="cursor: pointer">欢迎您：{{ userInfo.username + '-' + userInfo.account }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="logout" @click="logout">
        <a-icon class="icon" type="logout" />
        退出登录
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
      <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
        <el-form-item label="新密码" prop="password">
          <el-input style="width: 250px" type="password" v-model="formData.password" placeholder="新密码" size="small"></el-input>
          <span style="margin-left: 10px; color: #f56c6c">密码由8位以上包含大小写字母、特殊符号、数字组成</span>
        </el-form-item>
        <el-form-item label="确认密码" prop="password1">
          <el-input style="width: 250px" type="password" v-model="formData.password1" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small">修改</el-button>
          <el-button @click="dialogVisible = false" size="small">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getWWWInfoAPI, updatePasswordAPI } from './api'
import { addTeacher, editTeacher } from '@/views/feedback/feedback-manage/api'

export default {
  name: 'TopMenu',
  model: {
    prop: 'collapsed'
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userName: '',
      dialogVisible: false,

      formData: {
        password: '',
        password1: ''
      },

      rules: {
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
            message: '密码由8位以上包含大小写字母、特殊符号、数字组成',
            trigger: 'blur'
          }
        ],
        password1: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },

  created() {
    this.userName = localStorage.getItem('userName')
  },

  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    ...mapState({ name: (state) => state.user.account }),
    bread() {
      let breadList = []
      breadList = this.$route.matched.map((item) => {
        return {
          path: item.path,
          name: item.meta.name
        }
      })
      return breadList
    }
  },

  methods: {
    changeCollapsed() {
      this.$emit('input', !this.collapsed)
      this.$bus.$emit('showLogo')
    },

    handleCommand(command) {
      if (command === 'updatePwd') {
        this.dialogVisible = true
        this.formData = {
          password: '',
          password1: ''
        }
        this.$nextTick(() => {
          this.$refs.form.resetFields()
        })
      }
    },

    onSubmit() {
      const { password } = this.formData
      const data = { password }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          updatePasswordAPI(data).then((res) => {
            this.$message.success('更新密码成功')
            this.formData.password = ''
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },

    handleClose() {
      this.dialogVisible = false
    },

    getWWWInfo() {
      getWWWInfoAPI().then((res) => {
        console.log(res, '@@@')
        this.wwwInfo = res
      })
    },

    refresh() {
      history.go(0)
    },

    logout() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;

  .icon {
    margin-right: 20px;
    font-size: 16px;
  }

  .right {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 20px;
      margin-right: 0;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }
}
</style>
